@import '~bootstrap/scss/bootstrap'; 

html, body, #root, .main-container {
    height: 100%; 
    width: 100%; 
    font-family: "Montserrat", sans-serif;
}

// Begin styling for website 
.main-container {
    background-color: black;//rgb(28, 39, 25); 
    color: white; 
    display: grid; 
    grid-template-rows: 0.09fr 1fr; 
}
.main-content {
    overflow-y: auto;
    overflow-x: hidden; 
    scroll-behavior: smooth;
    max-width: 1280px;
    margin-left: auto; 
    margin-right: auto; 
    width: 100%; 
    section {
        width: 100%; 
        &#home {
            height: 100%; 
        }
        padding-bottom: 8rem;
        // margin: 1rem;
    }
    // margin: 0.5rem;
}

.header-title {
    //color: rgb(28, 39, 25);
    color: white; 
    position: relative; 
    font-weight: bold; 
    font-size: 4.5rem; 
    height: 50%; 
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    -webkit-text-stroke-width: 2px; 
    -webkit-text-stroke-color: rgb(28, 39, 25);
    //-webkit-text-stroke-color: white;
    text-align: center;

    img {
        width: 5rem;
    }

}
.hero-section {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 59%, rgba(0, 0, 0, 0.65) 100%)
}
.hero-header {
    width: 100%; 
    height: 50%;
    display: flex; 
    justify-content: left; 
    align-items: center; 
    
    .hero-box {
        background-color: black; 
        padding: 2rem;
        margin-left: 1.5rem;
        height: 60%;
        max-width: 45%;
        display: flex; 
        flex-direction: column; 
        gap: 1rem;
        
        
        .hero-box-title {
            font-weight: bold; 
            // font-family: "Montserrat", sans-serif;
            text-transform: uppercase; 
            font-size: 1.75rem; 
        }

        .hero-box-subtext {
            font-size: 1.1rem;
        }

        .hero-box-button {
            button {
                background-color: white; 
                padding: 1rem; 
                width: 40%;
                border: none; 
                font-weight: bolder; 
                letter-spacing: 0.1rem;
                
                transition: ease-out 0.4s; 
                box-shadow: inset 0 0 0 0 black; 
                border: 1px solid white;

                &:hover {
                    box-shadow: inset 13em 0 0 0 black;
                    color: white;
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    
}
  
::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
    border-radius: 7px; 
}

::-webkit-scrollbar-track {
    background: transparent;
}

//Header styles 
.header-container {
    display: grid; 
    grid-template-columns: 0.1fr 1fr; 
}
.nav-container {
    display: flex; 
    align-items: center; 
    justify-content: center;

}
.header-nav-bar {
    background-color: white; //#001300; 
    padding: 0 !important;
    padding-top: 0.25rem !important;
}

.nav-link{
    font-weight: bold; 
    font-size: 1.2rem;
    color: black ;
    text-transform: uppercase;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 1.5rem !important; 
    padding-left: 1.5rem !important;
    &.active {
        color: grey !important;
    }
    &:hover {
        color: grey;
    }
}
//Footer styles 
.footer-container {
    background-color: white !important;//#001300 !important
}




//Section styling
section {
    // max-height: 800px; 
}

section > div {
    height: 100%; 
    width: 100%; 
}

.section-header {
    display: flex; 
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-transform: uppercase; 

    span {
        padding-bottom: 4px;
        position: relative;
        text-decoration: none;
        width: 75%; 
        text-align: center;
    }
    span::after{
        background: white;
        background: linear-gradient(to right, transparent 0%, white 50%, transparent 100%);  
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
    }
}
.section-subheader {
    display: flex; 
    justify-content: center; 
    margin-top: 0.5rem;
    font-size: 1.3rem;
}
.section-body {
    margin-top: 2rem;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    .section-paragraph {
        text-indent: 30px; 
    }

    form {
        font-family: "Montserrat", sans-serif;
    }
}

.section-body-wrapped {
    width: 75%;
    margin: auto;
    text-align: center;

    p {
        text-indent: 30px;
    }
}

//Home Section 
.header-image {
    width: 80%;
}
.header-image > img { 
    width: 100%;
    border-radius: 11px; 
}

//Gallery
// .carousel-root {
//     height: 75% !important; 
//     width: 75% !important;
    
// }
// .carousel {
//     height: 100% !important;
// }
// .slider-wrapper {
//     height: 100% !important; 
// }
// .slider {
//     height: 100% !important;
// }
// .slide {
//     height: 100% !important; 
//     z-index: 100 !important;
// }
// .slide > div {
//     height: 100% !important;
// }
// .carousel .slide img {
//     height: 100% !important; 
//     width: auto !important; 
// }

.story-section-body {
    display: flex; 
}
.story-main-image-container { 
    flex: 1; 
}


.story-images {
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    gap: 1rem; 
    width: 100%;
    padding: 1rem;
    margin-top: 2rem;
    img {
        width: 100%;
        max-height: 390px;
        object-fit: cover;
    }
}


//Form styling 
.contact-form {
    width: 50%; 
    margin: auto; 
}
.form-submit {
    margin-top: 3rem; 
    button {
        background-color: white; 
        padding: 1rem; 
        width: 40%;
        border: none; 
        font-weight: bolder; 
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        transition: ease-out 0.4s; 
        box-shadow: inset 0 0 0 0 black; 
        border: 1px solid white;

        &:hover {
            box-shadow: inset 13em 0 0 0 black;
            color: white;
    }
    }
}


//image carousel

// .slide {
//     img {
//         max-height: 600px; 
//         object-fit: cover;
//     }
// }


.carousel {
    height: 600px; 
    width: 800px; 
}

.carousel-item {
    img {
        height: 600px; 
        object-fit: cover; 
    }
}

//Navigation Links 
.nav-link {
    cursor: pointer; 
}




// Components
.component-donate-button {
    background-color: black; 
    color: white; 
    border: 1px solid black; 
    font-weight: bold; 
    font-size: 1.2rem; 
    width: 5em; 
    padding: 0.5em;    

    transition: ease-out 0.4s; 
    box-shadow: inset 0 0 0 0 white; 

    &:hover {
        // background-color: white;
        // color: black; 
        // border-color: black;
        box-shadow: inset 5em 0 0 0  white; 
        color: black;
    }
}

.transition-button {
    background-color: white; 
    color: black; 
    border: 1px solid white; 
    font-weight: bold; 
    font-size: 1.2rem; 
    width: 6em; 
    padding: 0.5em;    

    transition: ease-out 0.4s; 
    box-shadow: inset 0 0 0 0 black; 

    &:hover {
        // background-color: white;
        // color: black; 
        // border-color: black;
        box-shadow: inset 6em 0 0 0  black; 
        color: white;
    }

    &.inverse {
        background-color: black; 
        color: white; 
        border: 1px solid black; 
        box-shadow: inset 0 0 0 0 white; 

        &:hover {
            box-shadow: inset 6em 0 0 0  white; 
            color: black;
        }
    }
}

// Event filter button styles 
.event-filter-button { 
    width: 10em !important; 

    &:hover {
        box-shadow: inset 10em 0 0 0  black !important; 
        color: white !important;
    }
}


// Mobile layout style adjustments
@media only screen and (max-width: 600px) {
    .hero-box {
        max-width: 100% !important; // Adjust hero box width for mobile
        height: 100% !important; // Adjust hero box height for mobile
        margin-left: 1em !important; 
    }
    .section-body {
        margin-left: 1em;  
        margin-right: 1em; 
    }
    .section-body-wrapped {
        width: 100%; // Adjust section body width for mobile
    }
    
    // Adjust the image container and text wrapping for mobile screens
    .story-main-image-container {

        margin-bottom: 1em; // Add margin for spacing
        max-width: 100%; // Prevent the image from being too large
        display: flex; 
        justify-content: center;
    }

    .story-section-body {
        display: block; // Change from flex to block for better stacking on small screens
        text-align: left; // Ensure text is left-aligned even on smaller screens
    }

    img {
        max-width: 100%; // Scale image to fit within the screen width
        height: auto; // Ensure image maintains aspect ratio
    }

    .contact-form {
        width: 100%; 
        margin-left: 1em; 
        margin-right: 1em; 
    }

    .navbar-collapse-wrapper {
        width: 100%;
    }
}

.btn-close {
    background-color: white; 
}